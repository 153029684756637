(function($) {
  Drupal.behaviors.storeLocatorBlock = {
    attach: function(context, settings) {
      if (typeof google == 'undefined') return;

      window.site = site || {};
      site.locatorBlock = site.locatorBlock || {};
      site.locatorBlock.errors = site.locatorBlock.errors || {};

      var resultsTemplate = $('#store-locator-block__results_template', context).html();
      var domain = generic.env.domain;
      var markerPath = domain + '/sites/esteelauder/themes/estee_base/img/locator/marker_dot_active.png';
      var mapOptions = {
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        zoom: 16
      };
      var map;
      var doors;
      var closestDoor = {};
      var latLng;

      var $block = $('.store-locator-block', context);
      var $map = $('.store-locator-block__map', $block);
      var $loadingMsg = $('.store-locator-block__loading-message', $block);
      var $results = $('.store-locator-block__results', $block);
      var $errors = $('.store-locator-block__errors', $block);

      function _init() {
        // Ensure browser supports Geolocation
        if (!navigator.geolocation) return _setError(site.locatorBlock.errors.noGeo);

        // Ensure locationData is set
        if (typeof(site.locator.locationData) == 'undefined') {
          return _setError( site.locatorBlock.errors.locationsFailed );
        }

        // Get the current position and perform lookup
        navigator.geolocation.getCurrentPosition( _lookupLocation, _geoFail );
      }

      function _lookupLocation(pos) {
        generic.jsonrpc.fetch({
          method: 'locator.doorsandevents',
          params: [{
            fields: 'DOORNAME, ADDRESS, ADDRESS2, STATE_OR_PROVINCE, CITY, COUNTRY, ZIP_OR_POSTAL',
            show_stores_list: 'all',
            uom: 'mile',
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
            // @TODO: it would be nice if the backend had a way to limit results by
            // a number (eg. limit: "1", which would just get the closest door).
            // Unfortunately it looks like radius is the only way to reduce the
            // results, and if it's not set the backend returns several MB of json.
            radius : 20
          }],
          onSuccess: _displayResults,
          onFailure: _lookupFail
        });
      }

      function _geoFail() {
        _setError( site.locatorBlock.errors.geoFailed );
      }

      function _lookupFail(jsonRpcResponse) {
        var errorObjectsArray = jsonRpcResponse.getMessages();
        _setError(errorObjectsArray[0].text);
      }

      function _setError(errorText) {
        if (errorText) {
          $loadingMsg.remove();
          $errors.show().text(errorText);
        }
      }

      function _displayResults(resultData) {
        if (!resultData) return _setError( site.locatorBlock.errors.noResults );
        doors = resultData.getValue().results;
        if (!doors) return _setError( site.locatorBlock.errors.noResults );

        _closestDoor(doors);

        // Remove the loading class, init the google map, center on the closest door
        // and drop a marker
        $block.removeClass('loading');
        latLng = new google.maps.LatLng(closestDoor.LATITUDE, closestDoor.LONGITUDE);

        mapOptions.center = latLng;
        $loadingMsg.remove();
        map = new google.maps.Map( $map.get(0), mapOptions );
        window.map = map;
        marker = new google.maps.Marker({
          position: latLng,
          map: map,
          icon: markerPath
        });

        // Render the door info
        $results.prepend( Mustache.render( resultsTemplate, closestDoor ) );
      }

      function _closestDoor(doors) {
        // This wouldn't be necessary if the backend wasn't so lazy.
        var distance = 0;
        for (var doorID in doors) {
          distance = parseFloat( doors[doorID].DISTANCE );
          if (typeof closestDoor.DISTANCE == 'undefined' || distance < closestDoor.DISTANCE) {
            closestDoor = doors[doorID];
          }
        }
        return closestDoor;
      }

      _init();
    }
  };
})(jQuery);
